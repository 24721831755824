<template>
  <div>Date Card</div>
  <div>
    <button :class="disabledStartBtn
      ? 'opacity-50 cursor-not-allowed'
      : 'hover:bg-blue-700'
      " class="mr-2 bg-blue-500 text-white font-bold py-2 px-4 rounded" @click="onStartClick">
      Start
    </button>
    <button :class="disabledEndBtn ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
      " class="bg-blue-500 text-white font-bold py-2 px-4 rounded" @click="onEndClick">
      Ende
    </button>
  </div>

  <div class="mt-2">
    <button class="mr-2 hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
      @click="onOpenCustomTime()">
      customTime
    </button>
    <input v-if="openCustomTime" type="time" v-model="customTime" />
  </div>

  <div class="mt-2">
    <button :class="disabledBreakStartBtn()
      ? 'opacity-50 cursor-not-allowed'
      : 'hover:bg-blue-700'
      " class="mr-2 bg-blue-500 text-white font-bold py-2 px-4 rounded" @click="onBreakStartClick">
      <IconSelector :iconText="'coffee'" />
    </button>
    <span class="w-2"></span>
    <button :class="disabledBreakEndBtn() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
      " class="bg-blue-500 text-white font-bold py-2 px-4 rounded" @click="onBreakEndClick">
      <IconSelector :iconText="'coffeeOff'" />
    </button>
  </div>

  <div class="pt-4 flex flex-wrap justify-center">
    <div class="flex flex-nowrap pb-2">
      <button class="ml-auto hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="addUserTimeSpecialDay('illness')">
        <IconSelector :iconText="'illness'" />
      </button>
      <span class="w-2"></span>
      <button class="mr-auto hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="addUserTimeSpecialDay('half_workday')">
        <IconSelector :iconText="'halfWorkday'" />
      </button>
    </div>
    <div class="w-2"></div>
    <div class="flex flex-nowrap pb-2">
      <button class="ml-auto hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="addUserTimeSpecialDay('vacation')">
        <IconSelector :iconText="'vacation'" />
      </button>
      <span class="w-2"></span>
      <button class="mr-auto hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="addUserTimeSpecialDay('half_vacation')">
        <IconSelector :iconText="'halfVacation'" />
      </button>
    </div>
    <div class="w-2"></div>
    <div class="flex flex-nowrap pb-2">
      <button class="ml-auto hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="addUserTimeSpecialDay('holiday')">
        <IconSelector :iconText="'holiday'" />
      </button>
      <span class="w-2"></span>
      <button class="mr-auto hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
        @click="addUserTimeSpecialDay('half_holiday')">
        <IconSelector :iconText="'halfHoliday'" />
      </button>
    </div>
    <div class="w-2"></div>
  </div>

  <!-- <div>
    {{ userBreakTimesData }}
  </div> -->

  <div>
    Einträge {{ cardData.weekday.short }}, {{ cardData.localDate }} von
    {{ userData.first_name }}
  </div>

  <!-- a{{ getDateTypes }}a -->
  <div>
    <div class="table w-full bg-white">
      <div class="table-row-group">
        <div class="table-row">
          <div class="table-cell">Start</div>
          <div class="table-cell">Ende</div>
          <div class="table-cell">{{ toHoursAndMinutes(buildSum) }}</div>
          <div class="table-cell"></div>
          <div class="table-cell"></div>
        </div>
      </div>
      <div
        v-for="item in userTimesData.concat(userBreakTimesData.map((e) => { return { ...e, date_type_title: 'break' } }))"
        :key="item.id" class="table-row">
        <div class="table-cell">
          {{ getStartDate(item) }}
        </div>
        <div class="table-cell">
          <template v-if="item.end_date != null">
            {{ getEndDate(item) }}
          </template>
          <!-- <template v-else>
            <TimeRefresher class="border-none" :date="item.start_date" />
          </template> -->
        </div>
        <div class="table-cell">
          <template v-if="item.end_date != null">
            {{ toHoursAndMinutes(item.duration_in_minutes) }}
          </template>
          <template v-else>
            <TimeRefresher class="border-none" :date="item.start_date" />
          </template>
        </div>
        <div class="table-cell">
          <!-- {{ item.date_type_title }} -->
          <IconSelector :iconText="item.date_type_title" class="text-zinc-500 border-none" />
          <!-- {{ item.date_type_id }} -->
          <!-- {{ getDateType(item.date_type_id) }} -->
          <!-- <IconSelector class="text-gray-400" :iconText="getDateType(item.date_type_id).icon.iconTitle" /> -->
        </div>
        <div class="table-cell">
          <div class="border-none">
            <button v-if="item.date_type_title != 'break'"
              class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-2 rounded"
              @click="onDeleteUserTime(item)">
              <IconSelector :iconText="'trashCanOutline'" />
            </button>
            <button v-else class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-0 px-2 rounded"
              @click="onDeleteUserBreakTime(item)">
              <IconSelector :iconText="'trashCanOutline'" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <dialog ref="askDialog" :open="openDialog">
    <form method="dialog">
      <div>Ladida</div>
    </form>
  </dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IconSelector from "./combinedIcons/IconSelector.vue";
import TimeRefresher from "./TimeRefresher.vue";

export default {
  name: "DateCard",
  components: {
    IconSelector,
    TimeRefresher
  },
  props: {
    cardData: { id: "" },
    userData: { localDate: "" },
  },
  data() {
    return {
      selectedButton: "workday",
      openDialog: false,
      openCustomTime: false,
      customTime: new Date().toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    };
  },
  computed: {
    ...mapGetters("defaults", {
      getDateTypes: "getDateTypes",
    }),
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
    }),
    ...mapGetters("users", {
      userTimesData: "userTimesData",
      userBreakTimesData: "userBreakTimesData",
      buildSum: "buildSum",
    }),
    disabledStartBtn() {
      return this.userTimesData != null ? this.userTimesData.some((e) => e.end_date == null) : false;
    },
    disabledEndBtn() {
      return this.userTimesData != null ? this.userTimesData.every((e) => e.end_date != null) : true;
    },
  },
  created() {
    this.callUserTimeByDate(this.userData.id, this.cardData.date);
    this.callUserBreakTimeByDate(this.userData.id, this.cardData.date);
  },
  methods: {
    ...mapActions("users", {
      getUserTimeByDate: "getUserTimeByDate",
      getUserBreakTimeByDate: "getUserBreakTimeByDate",
      deleteUserTime: "deleteUserTime",
      setUserStart: "setUserStart",
      setUserEnd: "setUserEnd",
      setUserBreakStart: "setUserBreakStart",
      setUserBreakEnd: "setUserBreakEnd",
      deleteUserBreakTime: "deleteUserBreakTime",
    }),
    async callUserTimeByDate(userDataId, cardDataDate) {
      await this.getUserTimeByDate({ date: cardDataDate, userId: userDataId });
    },
    async callUserBreakTimeByDate(userDataId, cardDataDate) {
      await this.getUserBreakTimeByDate({ date: cardDataDate, userId: userDataId });
    },
    onOpenCustomTime() {
      this.customTime = new Date().toLocaleTimeString("de-DE", {
        hour: "2-digit",
        minute: "2-digit",
      })
      this.openCustomTime = !this.openCustomTime;
    },
    setSelectedButton(selectedButton) {
      this.selectedButton = selectedButton;
    },
    addUserTimeSpecialDay(dateType) {
      this.onStartClick(null, dateType);
    },

    getDateType(dateTypeId) {
      return this.getDateTypes.find((e) => e.id == dateTypeId);
    },
    getStartDate(item) {
      if (item.start_date) {
        return new Date(item.start_date).toLocaleTimeString('de-DE').substring(0, 5);
      } else {
        return "";
      }
    },
    getEndDate(item) {
      if (item.end_date) {
        return new Date(item.end_date).toLocaleTimeString('de-DE').substring(0, 5);
      } else {
        return ''
      }
    },
    toHoursAndMinutes(totalMinutes) {
      if (totalMinutes == null) {
        return "";
      }

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      const hoursStr = hours.toString().padStart(2, "0");
      const minutesStr = minutes.toString().padStart(2, "0");

      return `${hoursStr}:${minutesStr}`;
    },
    askDeleteUserTime() {
      this.openDialog = !this.openDialog;
      if (this.openDialog) {
        this.$refs.askDialog.showModal();
      } else {
        this.$refs.askDialog.close();
      }
    },
    async onDeleteUserTime(item) {
      await this.deleteUserTime({
        id: item.id,
        date: this.cardData.date,
        userId: this.userData.id,
      })
    },
    async onDeleteUserBreakTime(item) {
      await this.deleteUserBreakTime({
        id: item.id,
        date: this.cardData.date,
        userId: this.userData.id,
      })
    },
    onStartClick(_ev, dateTypeStr = "workday") {
      if (!this.disabledStartBtn) {
        const now = new Date();
        if (this.openCustomTime) {
          now.setHours(this.customTime.split(':')[0])
          now.setMinutes(this.customTime.split(':')[1])
        }
        let zz = new Date(this.cardData.date);
        const dateParts = {
          y: zz.getFullYear(),
          m: zz.getMonth(),
          d: zz.getDate(),
          h: now.getHours(),
          mm: now.getMinutes(),
        };
        let startDate = new Date(
          dateParts.y,
          dateParts.m,
          dateParts.d,
          dateParts.h,
          dateParts.mm
        );

        this.setUserStart({
          date: this.cardData.date,
          startDate: startDate,
          userId: this.userData.id,
          dateTypeStr: dateTypeStr,
        })
      }
    },
    onEndClick() {
      if (!this.disabledEndBtn) {
        const now = new Date();
        if (this.openCustomTime) {
          now.setHours(this.customTime.split(':')[0])
          now.setMinutes(this.customTime.split(':')[1])
        }
        let zz = new Date(this.cardData.date);
        const dateParts = {
          y: zz.getFullYear(),
          m: zz.getMonth(),
          d: zz.getDate(),
          h: now.getHours(),
          mm: now.getMinutes(),
        };
        let endDate = new Date(
          dateParts.y,
          dateParts.m,
          dateParts.d,
          dateParts.h,
          dateParts.mm
        );

        this.setUserEnd({
          date: this.cardData.date,
          endDate: endDate,
          userId: this.userData.id,
        });
      }
    },
    disabledBreakStartBtn() {
      return this.userBreakTimesData.some((e) => e.end_date == null);
    },
    disabledBreakEndBtn() {
      return this.userBreakTimesData.every((e) => e.end_date != null);
    },
    onBreakStartClick() {
      if (!this.disabledBreakStartBtn()) {
        const now = new Date();
        if (this.openCustomTime) {
          now.setHours(this.customTime.split(':')[0])
          now.setMinutes(this.customTime.split(':')[1])
        }
        let zz = new Date(this.cardData.date);
        const dateParts = {
          y: zz.getFullYear(),
          m: zz.getMonth(),
          d: zz.getDate(),
          h: now.getHours(),
          mm: now.getMinutes(),
        };
        let startDate = new Date(
          dateParts.y,
          dateParts.m,
          dateParts.d,
          dateParts.h,
          dateParts.mm
        );


        this.setUserBreakStart({
          date: this.cardData.date,
          startDate: startDate,
          userId: this.userData.id,
        })
      }
    },
    onBreakEndClick() {
      if (!this.disabledBreakEndBtn()) {
        const now = new Date();
        if (this.openCustomTime) {
          now.setHours(this.customTime.split(':')[0])
          now.setMinutes(this.customTime.split(':')[1])
        }
        let zz = new Date(this.cardData.date);
        const dateParts = {
          y: zz.getFullYear(),
          m: zz.getMonth(),
          d: zz.getDate(),
          h: now.getHours(),
          mm: now.getMinutes(),
        };
        let endDate = new Date(
          dateParts.y,
          dateParts.m,
          dateParts.d,
          dateParts.h,
          dateParts.mm
        );

        this.setUserBreakEnd({
          date: this.cardData.date,
          endDate: endDate,
          userId: this.userData.id,
        });
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
