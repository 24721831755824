<template>
  <div class="flex justify-center">
    <div class="w-[500px]">
      <!-- <h4>Login Form</h4> -->
      <form>
        <div class="mb-3">
          <label for="txtEmail" class="form-label">Email</label>
          <input type="text" class="form-control" id="txtEmail" aria-describedby="emailHelp" v-model="email" />
        </div>
        <div class="mb-3">
          <label for="txtPassword" class="form-label">Password</label>
          <input type="password" class="form-control" id="txtPassword" v-model="password" @keyup.enter="login()" />
        </div>
        <button type="button" class="mr-2 hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-4 rounded"
          @click="login()">
          Anmelden
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters("auth", {
      getLoginApiStatus: "getLoginApiStatus",
    }),
  },
  methods: {
    ...mapActions("auth", {
      actionLoginApi: "loginApi",
    }),

    async login() {
      const payload = {
        email: this.email,
        password: this.password,
      };

      await this.actionLoginApi(payload);

      if (this.getLoginApiStatus == "success") {
        this.$router.push("/home");
      } else {
        alert("failed");
      }
    },
  },
};
</script>
