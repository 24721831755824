<template>
  <div>
    <div class="flex justify-evenly">
      <CheckBox
        v-for="item in tabControlYears"
        :key="item.id"
        :item="item"
        @onToggleItem="toggleSelectedYear"
      />
    </div>
    <table class="border-solid border-1">
      <tr class="border-solid border-1">
        <th class="border-solid border-1">kw</th>
        <th class="border-solid border-1">Date</th>
        <th class="border-solid border-1">timeDuration</th>
        <th class="border-solid border-1">breakDuration</th>
        <th class="border-solid border-1">duration</th>
        <th class="border-solid border-1">delta</th>
      </tr>
      <tr
        v-for="entry in filteredConcatTimes"
        class="border-solid border-1"
        :key="entry.kwDate"
      >
        <td class="border-solid border-1">
          {{ entry.kw.toString().padStart(2, "0") }}
        </td>
        <td class="border-solid border-1">{{ entry.kwDateLocale }}</td>
        <td class="border-solid border-1">{{ entry.timeDurationHh }}</td>
        <td class="border-solid border-1">{{ entry.breakDurationHh }}</td>
        <td class="border-solid border-1">{{ entry.duration }}</td>
        <td class="border-solid border-1">{{ entry.delta }}</td>
      </tr>
    </table>

    <div class="h-[20rem]">
      <LineChart :chartData="lineChartData" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { IS08601WeekNo } from "../shared/dateHelper"
import LineChart from "./charts/LineChart.vue"
import { LineFitter, SquareFitter } from "../shared/mathGraphHelper"
import CheckBox from "./CheckBox.vue"

export default {
  components: {
    CheckBox,
    LineChart,
  },
  data() {
    return {
      activeYears: [new Date().getFullYear()],
    }
  },
  computed: {
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
      getUserProfile: "getUserProfile",
    }),
    ...mapGetters("users", {
      userTimesDataByWeeks: "userTimesDataByWeeks",
      userBreakTimesDataByWeeks: "userBreakTimesDataByWeeks",
    }),
    filteredConcatTimes() {
      return this.activeYears.length == 0
        ? this.concatTimes
        : this.concatTimes.filter((e) => this.activeYears.includes(e.y))
    },
    tabControlYears() {
      const yearList = Array.from(
        new Set(this.concatTimes.map((e) => e.y))
      ).sort()
      let retArr = []
      yearList.forEach((e, ind) => {
        retArr.push({
          id: ind,
          title: e,
          active: this.activeYears.includes(e),
        })
      })

      return retArr
    },
    lineChartData() {
      const labels = []
      const timeDuration = []
      const breakDuration = []
      const duration = []
      const delta = []

      const b = this.getUserProfile.defaultWeekWorktimeInMinutes
      this.filteredConcatTimes.forEach((entry) => {
        labels.push(entry.kw)

        timeDuration.push((entry.timeDuration / 60).toFixed(2))
        breakDuration.push((entry.breakDuration / 60).toFixed(2))

        const a = entry.timeDuration - entry.breakDuration

        duration.push((a / 60).toFixed(2))
        delta.push(((a - b) / 60).toFixed(2))
      })

      return {
        chartData: {
          labels: labels,
          datasets: [
            {
              label: "timeDuration",
              backgroundColor: "#f87979",
              data: timeDuration,
              yAxisID: "y",
              cubicInterpolationMode: "monotone",
            },
            {
              label: "breakDuration",
              backgroundColor: "#0d6efd",
              data: breakDuration,
              yAxisID: "y1",
              cubicInterpolationMode: "monotone",
            },
            {
              label: "duration",
              backgroundColor: "#008000",
              data: duration,
              yAxisID: "y",
              cubicInterpolationMode: "monotone",
            },
            {
              label: "Delta",
              backgroundColor: "#adff2f",
              data: delta,
              yAxisID: "y1",
              // cubicInterpolationMode: 'monotone',
            },
          ],
        },
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              type: "linear",
              display: true,
              position: "left",

              // grid line settings
              grid: {
                // drawOnChartArea: false, // only want the grid lines for one axis to show up
                color: function (context) {
                  if (context.tick.value > 37.5 && context.tick.value < 39.5) {
                    return "#ff8c00"
                  }

                  return "#0000001a"
                },
              },
            },
            y1: {
              type: "linear",
              display: true,
              position: "right",
            },
          },
        },
      }
    },
    concatTimes() {
      const output = []
      this.userTimesDataByWeeks.forEach((entry) => {
        const duration = (entry.duration / 60).toFixed(2)
        const delta = (
          (entry.duration - this.getUserProfile.defaultWeekWorktimeInMinutes) /
          60
        ).toFixed(2)
        output.push({
          kw: IS08601WeekNo(entry.weekly),
          y: new Date(entry.weekly).getFullYear(),
          kwDate: entry.weekly,
          kwDateLocale: new Date(entry.weekly).toLocaleDateString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            // weekday: "short",
          }),
          timeDuration: entry.duration,
          timeDurationHh: (entry.duration / 60).toFixed(2),
          breakDuration: 0,
          breakDurationHh: "0.00",
          duration,
          delta,
        })
      })
      this.userBreakTimesDataByWeeks.forEach((entry) => {
        const ind = output.findIndex((eo) => eo.kwDate == entry.weekly)
        if (ind == -1) {
          output.push({
            kwDate: entry.weekly,
            kwDateLocale: new Date(entry.weekly).toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              // weekday: "short",
            }),
            timeDuration: 0,
            timeDurationHh: "0.00",
            breakDuration: entry.duration,
            breakDurationHh: (entry.duration / 60).toFixed(2),
            duration: ((-1 * entry.duration) / 60).toFixed(2),
            delta: (
              (-1 * entry.duration -
                this.getUserProfile.defaultWeekWorktimeInMinutes) /
              60
            ).toFixed(2),
          })
        } else {
          output[ind].breakDuration = entry.duration
          output[ind].breakDurationHh = (entry.duration / 60).toFixed(2)
          const a = output[ind].timeDuration - entry.duration
          output[ind].duration = (a / 60).toFixed(2)
          output[ind].delta = (
            (a - this.getUserProfile.defaultWeekWorktimeInMinutes) /
            60
          ).toFixed(2)
        }
      })
      return output
    },
  },
  created() {
    this.callCreated()
  },
  methods: {
    toggleSelectedYear(item) {
      const year = item.title

      if (this.activeYears.includes(year)) {
        this.activeYears = this.activeYears.filter((i) => i !== year)
      } else {
        this.activeYears = [...this.activeYears, year]
      }
    },
    linearProject(data, x) {
      var fitter = new LineFitter()
      for (var i = 0; i < data.length; i++) {
        fitter.add(i, data[i])
      }
      return fitter.project(x)
    },
    squareProject(data, x) {
      var fitter = new SquareFitter()
      for (var i = 0; i < data.length; i++) {
        fitter.add(i, data[i])
      }
      return fitter.project(x)
    },
    ...mapActions("users", {
      getUserTimesSumWeeks: "getUserTimesSumWeeks",
      getUserBreakTimesSumWeeks: "getUserBreakTimesSumWeeks",
    }),
    minToHourStr(minutes) {
      return new Date(minutes * 60 * 1000).toISOString().slice(11, 16)
    },
    async callCreated() {
      await this.getUserTimesSumWeeks({
        userId: this.gettersAuthData.userId,
      })
      await this.getUserBreakTimesSumWeeks({
        userId: this.gettersAuthData.userId,
      })
    },
  },
}
</script>
