<template>
  <ul>
    <li>{{ calcMonthSum() }}</li>
  </ul>
  <table class="table-auto w-full">
    <thead>
      <tr>
        <th>KW</th>
        <th>Datum</th>
        <th>Minuten</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(item, index) in userTimesDateTimeRangeOutput" :key="index" :class="buildTableRowClass(item)">
        <td class="align-top">
          <template v-if="index == 0 || item.weekday == 1">
            {{ item.weekNumber }}: {{ formatCalcWeekSums(item) }}
            <!-- {{
              (
                userTimesDateWeekNumberSums.find(
                  (e) => e.kw == item.weekNumber
                ).kwSum / 60
              ).toFixed(2)
            }}
            ({{
              userTimesDateWeekNumberSums.find(
                (e) => e.kw == item.weekNumber
              ).planKwSum / 60
            }}) -->
          </template>
        </td>
        <td class="align-top">
          {{ item.localDateWithWeekday }}<br />
          <template v-if="item.dateTimeObj.length > 0">
            {{ minToHourStr(calcDelta(item).sumDayInMinutes) }}
            ({{ minToHourStr(calcDelta(item).planDayInMinutes) }})
            [{{ calcDelta(item).isPositiv ? '' : '-' }}{{ minToHourStr(calcDelta(item).deltaInMinutes) }}]
            <br />
          </template>
          {{ item.specialDay.title }}
        </td>
        <td class="align-top">
          <div v-if="item.dateTimeObj.concat(item.break.dateTimeObj).length > 0" class="card">
            <ul>
              <li v-for="(dtoi, index) in item.dateTimeObj.concat(item.break.dateTimeObj)" :key="index">
                <table class="w-full">
                  <tr>
                    <td class="align-top">
                      <IconSelector class="text-zinc-500" :iconText="getDateType(dtoi.dateTypeId).icon.iconTitle" />
                    </td>
                    <td class="align-top">
                      {{ dtoi.startTime }} - {{ dtoi.endTime }}<br />
                    </td>
                    <td class="align-top">
                      {{ minToHourStr(dtoi.durationInMinutes) }}<br />
                      <!-- {{ getDateType(dtoi.dateTypeId).icon.iconTitle }} -->
                      <!-- <br />{{ dtoi.userTimeId }} -->
                    </td>
                    <td class="align-top text-right pr-1">
                      <button
                        class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-blue-500 py-0 px-2 border border-blue-500 hover:border-transparent rounded"
                        @click="deleteUserTimeBtn({ id: dtoi.userTimeId })">
                        <IconSelector iconText="trashCanOutline" />
                        <!-- <TrashCanOutlineIcon /> -->
                      </button>
                    </td>
                  </tr>
                </table>
                <hr v-if="index < item.dateTimeObj.concat(item.break.dateTimeObj).length - 1" />
              </li>
              <!-- <li>{{ item.break }}</li> -->
              <!-- <li v-for="(val, index) in item.break.dateTimeObj" :key="index">
                <pre>{{ val }}</pre>
              </li> -->
            </ul>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import TrashCanOutlineIcon from "./combinedIcons/TrashCanOutlineIcon.vue";
import IconSelector from "./combinedIcons/IconSelector.vue";

export default {
  components: {
    // TrashCanOutlineIcon,
    IconSelector
  },
  data() {
    return {
    }
  },
  props: {
    userTimesDateWeekNumberSums: Array,
    userTimesDateTimeRangeOutput: Array,
    perDay: Number,
  },
  computed: {
    ...mapGetters("defaults", {
      getDateTypes: "getDateTypes",
    }),
  },
  methods: {
    ...mapActions("users", {
      deleteUserTime: "deleteUsersTime",
    }),
    minToHourStr(minutes) {
      return new Date(minutes * 60 * 1000).toISOString().slice(11, 16);
    },
    calcMonthSum() {
      let retElement = {
        ist: 0,
        plan: 0,
        delta: 0,
      }
      this.userTimesDateTimeRangeOutput.forEach((item, index) => {
        if (index == 0 || item.weekday == 1) {
          const { ist, plan, delta } = this.calcWeekSums(item)
          retElement.ist += parseFloat(ist)
          retElement.plan += parseFloat(plan)
          retElement.delta += parseFloat(delta)
        }
      })
      return {
        ist: retElement.ist.toFixed(2),
        plan: retElement.plan.toFixed(2),
        delta: retElement.delta.toFixed(2)
      }
    },
    formatCalcWeekSums(item) {
      const { ist, plan, delta } = this.calcWeekSums(item)
      return ist + ' (' + plan + ') [' + delta + ']'
    },
    calcWeekSums(item) {
      const element = this.userTimesDateWeekNumberSums.find(
        (e) => e.kw == item.weekNumber
      )
      const ist = (element.kwSum / 60).toFixed(2)
      const plan = (element.planKwSum / 60).toFixed(2)
      const delta = (ist - plan).toFixed(2)

      return { ist, plan, delta }
    },
    calcDelta(item) {
      const sumDayInMinutes = item.dateTimeObj.map((e) => e.durationInMinutes).reduce((a, b) => a + b, 0)
      const sumBreakDayInMinutes = item.break.dateTimeObj.map((e) => e.durationInMinutes).reduce((a, b) => a + b, 0)
      const planDayInMinutes = this.perDay * 60
      const deltaInMinutes = sumDayInMinutes - sumBreakDayInMinutes - planDayInMinutes
      const isPositiv = deltaInMinutes >= 0 ? true : false

      return {
        sumDayInMinutes: sumDayInMinutes - sumBreakDayInMinutes,
        planDayInMinutes,
        deltaInMinutes: Math.abs(deltaInMinutes),
        isPositiv,
      }
    },
    getDateType(dateTypeId) {
      return this.getDateTypes.find((e) => e.id == dateTypeId);
    },
    deleteUserTimeBtn(params) {
      this.deleteUserTime(params)
    },
    buildTableRowClass(item) {
      return [0, 6].includes(item.weekday) || item.specialDay.title != null
        ? 'bg-gray-300'
        : 'even:bg-gray-50 odd:bg-white'
    }
  }
}
</script>
