<template>
  <div @click="onToggleItem">
    <p :class="buildClass">{{ item.title }}</p>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    item: Object,
  },
  computed: {
    buildClass() {
      return this.item.active ? "text-green-600" : "text-blue-600"
    },
  },
  methods: {
    onToggleItem() {
      this.$emit('onToggleItem', this.item)
    },
  },
}
</script>
