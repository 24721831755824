<!-- <template>
  <HelloWorld msg=""/>
</template> -->

<template>
  <div>
    <!-- <header class="bg-white fixed top-0 w-full">
      <nav class="container py-3"> -->
      <!-- {{ getUserProfile }} -->
    <ul class="sticky inset-x-0 top-0 left-0 bg-[#e5e7eb] z-50 py-1 flex">

      <li v-if="getUserProfile.id == 0" class="mr-6">
        <router-link to="/login" class="ext-blue-500 hover:text-blue-800">Login</router-link>
      </li>
      <li v-if="getUserProfile.id !== 0" class="mr-6">
        {{ getUserProfile.email }}
      </li>
    </ul>
    <ul class="sticky inset-x-0 top-0 left-0 bg-[#e5e7eb] z-50 py-1 flex">
      <li v-if="getUserProfile.id !== 0 && getUserProfile.userRole == 'admin'" class="mr-6">
        <router-link to="/admin" class="text-blue-500 hover:text-blue-800">
          ADMIN
        </router-link>
      </li>
      <li v-if="getUserProfile.id !== 0" class="mr-6">
        <router-link to="/dashboard" class="text-blue-500 hover:text-blue-800">
          <ViewDashboardVariantOutlineIcon />
        </router-link>
      </li>
      <li v-if="getUserProfile.id !== 0" class="mr-6">
        <router-link to="/dashboardWeeks" class="text-blue-500 hover:text-blue-800">
          <ViewDashboardVariantOutlineIcon />
        </router-link>
      </li>
      <li v-if="getUserProfile.id !== 0" class="mr-6">
        <router-link to="/home" class="text-blue-500 hover:text-blue-800">
          <home-outline-icon />
        </router-link>
      </li>
      <li v-if="getUserProfile.id !== 0" class="mr-6">
        <router-link to="/settings" class="text-blue-500 hover:text-blue-800">
          <cogs-icon />
        </router-link>
      </li>
      <!-- <li v-if="getUserProfile.id !== 0" class="mr-6">
        <router-link to="/charts" class="text-blue-500 hover:text-blue-800">
          <FinanceIcon />
        </router-link>
      </li> -->
      <li v-if="getUserProfile.id !== 0" class="mr-6">
        <router-link to="/dashboardCharts" class="text-blue-500 hover:text-blue-800">
          <ChartTreeIcon />
        </router-link>
      </li>
      <li>
        <span class="text-blue-500 hover:text-blue-800" @click="logout()">
          <LogoutVariantIcon />
          <!-- <LogoutIcon /> -->
        </span>
      </li>
    </ul>
    <!-- </nav>
    </header> -->
    <div class="pt-1">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import HomeOutlineIcon from "./components/combinedIcons/HomeOutlineIcon.vue";
import CogsIcon from "./components/combinedIcons/CogsIcon.vue";
import ViewDashboardVariantOutlineIcon from "./components/combinedIcons/ViewDashboardVariantOutlineIcon.vue";
import LogoutVariantIcon from "./components/combinedIcons/LogoutVariantIcon.vue";
// import FinanceIcon from "./components/combinedIcons/FinanceIcon.vue";
import ChartTreeIcon from "./components/combinedIcons/ChartTreeIcon.vue";
// import LogoutIcon from "./components/combinedIcons/LogoutIcon.vue";

export default {
  name: "App",
  created() {
    this.checkStoredToken();
  },
  computed: {
    ...mapGetters("auth", {
      getUserProfile: "getUserProfile",
      getLogout: "getLogout",
    }),
  },
  methods: {
    ...mapActions("auth", {
      userLogout: "userLogout",
      checkStoredToken: "checkStoredToken",
    }),
    ...mapMutations("auth", {
      setLogout: "setLogout",
      setUserProfile: "setUserProfile",
    }),
    async logout() {
      await this.userLogout();
      if (this.getLogout) {
        const resetUser = {
          id: 0,
          lastName: "",
          firstName: "",
          email: "",
          phone: "",
        };
        this.setUserProfile(resetUser)
        this.setLogout(true)
        this.$router.push("/login")
      }
    },
  },
  components: {
    HomeOutlineIcon,
    CogsIcon,
    ViewDashboardVariantOutlineIcon,
    LogoutVariantIcon,
    ChartTreeIcon,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
