import Login from "./components/LoginForm.vue";
import Dashboard from "./components/DashboardForm.vue";
import DashboardWeeks from "./components/DashboardWeekForm.vue";
import Home from "./components/HelloWorld.vue";
import Settings from "./components/SettingsForm.vue";
// import Charts from "./components/ChartsForm.vue"
import DashboardCharts from "./components/DashboardChartsForm.vue";
import Admin from "./components/AdminForm.vue"
import store from "./store/index";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  { path: "/", component: Login, meta: { requiredAuth: true } },
  { path: "/login", component: Login, meta: { requiredAuth: false } },
  { path: "/dashboard", component: Dashboard, meta: { requiredAuth: true } },
  {
    path: "/dashboardWeeks",
    component: DashboardWeeks,
    meta: { requiredAuth: true },
  },
  { path: "/home", component: Home, meta: { requiredAuth: true } },
  { path: "/settings", component: Settings, meta: { requiredAuth: true } },
  // { path: "/charts", component: Charts, meta: { requiredAuth: true } },
  {
    path: "/dashboardCharts",
    component: DashboardCharts,
    meta: { requiredAuth: true },
  },
  { path: "/admin", component: Admin, meta: { requiredAuth: true } },
];

export const routeConfig = createRouter({
  history: createWebHistory(),
  routes: routes,
});

routeConfig.beforeEach(async (to, from, next) => {
  let userProfile = store.getters["auth/getUserProfile"];
  let isAuthenticated = localStorage.getItem("isAuthenticated");
  if (userProfile.id === 0 && isAuthenticated) {
    await store.dispatch("auth/userProfile");
    userProfile = store.getters["auth/getUserProfile"];
  }

  if (to.meta.requiredAuth) {
    if (userProfile.id === 0) {
      return next({ path: "/login" });
    }
  } else {
    if (userProfile.id !== 0) {
      return next({ path: "/home" });
    }
  }
  return next();
});
