<template>
  <div>
    <div>
      <h4>
        Welcome to your dashboard page {{ getUserProfile.firstName }}
        {{ getUserProfile.lastName }}!
      </h4>
    </div>
    <div>UserName: {{ gettersAuthData.userName }}</div>
    <div>EMail: {{ gettersAuthData.email }}</div>
    <div>Id: {{ gettersAuthData.userId }}</div>
    <div class="flex justify-evenly">
      <CheckBox
        v-for="item in tabControlYears"
        :key="item.id"
        :item="item"
        @onToggleItem="toggleSelectedYear"
      />
    </div>
    <MonthSelector
      :tabControls="
        activeYears.length == 0
          ? tabControls
          : tabControls.filter((e) => activeYears.includes(e.y))
      "
      :selectedTab="selectedTab"
      @onSetSelectedTab="setSelectedTab"
    />
    <div>Zusammenfassung</div>
    <hr />
    <div>
      <DashboardTable
        :userTimesDateTimeRangeOutput="userTimesDateTimeRangeOutput"
        :userBreakTimesDateTimeRangeOutput="userBreakTimesDateTimeRangeOutput"
        :userTimesDateWeekNumberSums="userTimesDateWeekNumberSums"
        :perDay="perDay"
        @onDeleteUserTimeBtn="onDeleteUserTimeBtn"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import { IS08601WeekNo } from "../shared/dateHelper"
import DashboardTable from "./DashboardTable.vue"
import MonthSelector from "./MonthSelector.vue"
import CheckBox from "./CheckBox.vue"

export default {
  computed: {
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
      getUserProfile: "getUserProfile",
    }),
    ...mapGetters("users", {
      userTimesDateTimeRange: "userTimesDateTimeRange",
      userBreakTimesDateTimeRange: "userBreakTimesDateTimeRange",
    }),
    tabControlYears() {
      const yearList = Array.from(new Set(this.tabControls.map((e) => e.y)))
      let retArr = []
      yearList.forEach((e, ind) => {
        retArr.push({
          id: ind,
          title: e,
          active: this.activeYears.includes(e),
        })
      })

      return retArr
    },
    tabControls() {
      let startDate = new Date("2023-10-01")
      let currentDate = new Date()

      let subDate = startDate
      let outArr = [
        {
          y: subDate.getFullYear(),
          m: subDate.getMonth(),
          title: subDate.toLocaleString("de-DE", { month: "long" }),
        },
      ]
      while (subDate < currentDate) {
        subDate.setMonth(subDate.getMonth() + 1)
        outArr.push({
          y: subDate.getFullYear(),
          m: subDate.getMonth(),
          title: subDate.toLocaleString("de-DE", { month: "long" }),
        })
      }

      return outArr
    },
  },
  components: {
    DashboardTable,
    MonthSelector,
    CheckBox,
  },
  data() {
    return {
      activeYears: [new Date().getFullYear()],
      userTimesDateTimeRangeOutput: [],
      userBreakTimesDateTimeRangeOutput: [],
      userTimesDateWeekNumberSums: [],
      selectedTab: new Date().toLocaleString("de-DE", { month: "long" }),
      WEEKDAYS: [
        { short: "So", long: "Sonntag" },
        { short: "Mo", long: "Montag" },
        { short: "Di", long: "Dienstag" },
        { short: "Mi", long: "Mittwoch" },
        { short: "Do", long: "Donnerstag" },
        { short: "Fr", long: "Freitag" },
        { short: "Sa", long: "Samstag" },
      ],
      perDay: 0,
    }
  },
  created() {
    const tDate = new Date()
    this.callUserTimesDateTimeRange(
      this.buildDates(tDate.getFullYear(), tDate.getMonth())
    )
    this.getApiDateTypes()
  },
  methods: {
    ...mapActions("defaults", {
      getApiDateTypes: "getApiDateTypes",
    }),
    ...mapActions("users", {
      getUserTimesDateTimeRange: "getUserTimesDateTimeRange",
      getUserBreakTimesDateTimeRange: "getUserBreakTimesDateTimeRange",
    }),
    toggleSelectedYear(item) {
      const year = item.title

      if (this.activeYears.includes(year)) {
        this.activeYears = this.activeYears.filter((i) => i !== year)
      } else {
        this.activeYears = [...this.activeYears, year]
      }
    },
    userTimesDateTimeRangeSummary() {
      this.userTimesDateWeekNumberSums = []
      this.userTimesDateTimeRangeOutput = []
      this.userBreakTimesDateTimeRangeOutput = []

      const workHoursPerWeek =
        this.getUserProfile.defaultWeekWorktimeInMinutes / 60
      const workDaysPerWeek = this.getUserProfile.defaultWeekWorkdays
      this.perDay = workHoursPerWeek / workDaysPerWeek

      this.userTimesDateTimeRange.forEach((entry) => {
        const ind = this.userTimesDateTimeRangeOutput.findIndex(
          (eo) => eo.date == entry.date_interval
        )
        if (ind == -1) {
          const dateEntry = new Date(entry.date_interval)
          const dateTimeObj = []
          let dateTimeSumObj = {
            durationInMinutes: 0,
          }
          if (entry.duration_in_minutes != null) {
            dateTimeSumObj.durationInMinutes = entry.duration_in_minutes
            dateTimeObj.push({
              userTimeId: entry.user_time_id,
              durationInMinutes: entry.duration_in_minutes,
              startTime: new Date(entry.start_date).toLocaleTimeString(
                "de-DE",
                { hour: "2-digit", minute: "2-digit" }
              ),
              endTime: new Date(entry.end_date).toLocaleTimeString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
              }),
              dateTypeId: entry.date_type_id,
            })
          }

          this.userTimesDateTimeRangeOutput.push({
            date: entry.date_interval,
            localDate: dateEntry.toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            }),
            localDateWithWeekday: dateEntry.toLocaleDateString("de-DE", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              weekday: "short",
            }),
            weekday: dateEntry.getDay(),
            weekdayObj: this.WEEKDAYS[dateEntry.getDay()],
            weekNumber: IS08601WeekNo(dateEntry),
            dateTimeObj,
            dateTimeSumObj,
            specialDay: {
              title: entry.sd_title,
              dateTypeId: entry.sd_date_type_id,
              defaultDuration: 0,
            },
            break: {
              dateBreakTimeSumObj: {
                durationInMinutes: 0,
              },
              dateTimeObj: [],
            },
          })
        } else {
          if (entry.duration_in_minutes != null) {
            this.userTimesDateTimeRangeOutput[
              ind
            ].dateTimeSumObj.durationInMinutes += entry.duration_in_minutes
            this.userTimesDateTimeRangeOutput[ind].dateTimeObj.push({
              userTimeId: entry.user_time_id,
              durationInMinutes: entry.duration_in_minutes,
              dateTypeId: entry.date_type_id,
              startTime: new Date(entry.start_date).toLocaleTimeString(
                "de-DE",
                { hour: "2-digit", minute: "2-digit" }
              ),
              endTime: new Date(entry.end_date).toLocaleTimeString("de-DE", {
                hour: "2-digit",
                minute: "2-digit",
              }),
            })
          }
        }
      })
      this.userBreakTimesDateTimeRange.forEach((entry) => {
        const ind = this.userTimesDateTimeRangeOutput.findIndex(
          (eo) => eo.date == entry.date_interval
        )
        if (ind != -1 && entry.duration_in_minutes != null) {
          this.userTimesDateTimeRangeOutput[
            ind
          ].break.dateBreakTimeSumObj.durationInMinutes +=
            entry.duration_in_minutes
          this.userTimesDateTimeRangeOutput[ind].break.dateTimeObj.push({
            userTimeId: entry.user_time_id,
            durationInMinutes: entry.duration_in_minutes,
            dateTypeId: entry.date_type_id,
            startTime: new Date(entry.start_date).toLocaleTimeString("de-DE", {
              hour: "2-digit",
              minute: "2-digit",
            }),
            endTime: new Date(entry.end_date).toLocaleTimeString("de-DE", {
              hour: "2-digit",
              minute: "2-digit",
            }),
          })
        }
      })

      const tWeekNumber = [
        ...new Set(this.userTimesDateTimeRangeOutput.map((e) => e.weekNumber)),
      ]
      const kwSums = []
      tWeekNumber.forEach((wNum) => {
        const t1 = this.userTimesDateTimeRangeOutput.filter(
          (e) => e.weekNumber == wNum
        )
        let kwSum = 0
        let planKwSum = 0
        t1.forEach((e) => {
          const date = new Date(e.date)
          switch (date.getDay()) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
              planKwSum += this.perDay * 60
              planKwSum -= e.specialDay.defaultDuration
              break
          }
          e.dateTimeObj.forEach((a) => {
            kwSum += a.durationInMinutes
          })
          e.break.dateTimeObj.forEach((a) => {
            kwSum -= a.durationInMinutes
          })
        })
        kwSums.push({
          kw: wNum,
          kwSum,
          planKwSum,
        })
      })
      this.userTimesDateWeekNumberSums = kwSums
    },
    userTimesDateTimeKWRangeSummary() {
      const tWeekNumber = []
      this.userBreakTimesDateTimeRange.forEach((entry) => {
        const entryDate = new Date(entry.date_interval)
        const kw = IS08601WeekNo(entryDate)
        const year = entryDate.getFullYear()
        const kwStr =
          year.toString().padStart(4, "0") + kw.toString().padStart(2, "0")
        const index = tWeekNumber.findIndex((e) => e.kwStr == kwStr)
        const breakDataObj = {
          durationInMinutes: entry.duration_in_minutes,
          dateTypeId: entry.date_type_id,
          startTime: new Date(entry.start_date).toLocaleTimeString("de-DE", {
            hour: "2-digit",
            minute: "2-digit",
          }),
          endTime: new Date(entry.end_date).toLocaleTimeString("de-DE", {
            hour: "2-digit",
            minute: "2-digit",
          }),
        }
        if (index == -1) {
          tWeekNumber.push({
            kwStr,
            timeData: [],
            sumTimeData: 0,
            breakData: [breakDataObj],
            sumBreakData: breakDataObj.durationInMinutes,
          })
        } else {
          tWeekNumber[index].breakData.push(breakDataObj)
          tWeekNumber[index].sumBreakData += breakDataObj.durationInMinutes
        }
      })
      this.userTimesDateTimeRange.forEach((entry) => {
        const entryDate = new Date(entry.date_interval)
        const kw = IS08601WeekNo(entryDate)
        const year = entryDate.getFullYear()
        const kwStr =
          year.toString().padStart(4, "0") + kw.toString().padStart(2, "0")
        const index = tWeekNumber.findIndex((e) => e.kwStr == kwStr)
        const timeDataObj = {
          durationInMinutes: entry.duration_in_minutes,
          dateTypeId: entry.date_type_id,
          startTime: new Date(entry.start_date).toLocaleTimeString("de-DE", {
            hour: "2-digit",
            minute: "2-digit",
          }),
          endTime: new Date(entry.end_date).toLocaleTimeString("de-DE", {
            hour: "2-digit",
            minute: "2-digit",
          }),
        }
        if (index == -1) {
          tWeekNumber.push({
            kwStr,
            timeData: [timeDataObj],
            sumTimeData: timeDataObj.durationInMinutes,
            breakData: [],
            sumBreakData: 0,
          })
        } else {
          tWeekNumber[index].timeData.push(timeDataObj)
          tWeekNumber[index].sumTimeData += timeDataObj.durationInMinutes
        }
      })
    },
    buildDates(oriY, oriM) {
      let date = new Date(oriY, oriM + 1, 0)
      const d = date.getDate()
      const m = date.getMonth() + 1
      const yearStr = date.getFullYear()
      const ddStr = d < 10 ? "0" + d.toString() : d.toString()
      const mmStr = m < 10 ? "0" + m.toString() : m.toString()

      return {
        endDateStr: yearStr + "-" + mmStr + "-" + ddStr,
        startDateStr: yearStr + "-" + mmStr + "-01",
      }
    },
    setSelectedTab(tab) {
      this.selectedTab = tab.title
      this.callUserTimesDateTimeRange(this.buildDates(tab.y, tab.m))
    },
    onDeleteUserTimeBtn() {
      const tab = this.tabControls.find((e) => e.title == this.selectedTab)
      this.callUserTimesDateTimeRange(this.buildDates(tab.y, tab.m))
    },
    async callUserTimesDateTimeRange(buildedDate) {
      await this.getUserBreakTimesDateTimeRange({
        startDate: buildedDate.startDateStr,
        endDate: buildedDate.endDateStr,
        userId: this.gettersAuthData.userId,
      })
      await this.getUserTimesDateTimeRange({
        startDate: buildedDate.startDateStr,
        endDate: buildedDate.endDateStr,
        userId: this.gettersAuthData.userId,
      })

      this.userTimesDateTimeRangeSummary()
      this.userTimesDateTimeKWRangeSummary()
    },
  },
}
</script>
