<template>
  <div class="p-2">
    <h1 class="">
      {{ msg }}
    </h1>
    <div class="pt-2 flex">
      <button class="ml-auto hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-1 rounded"
        @click="setSelectedDateByDirection('dateBefore')">
        <IconSelector :iconText="'calendarArrowLeft'" />
      </button>
      <span class="w-2"></span>
      <button class="hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-1 rounded"
        @click="setSelectedDateByDirection('dateNext')">
        <IconSelector :iconText="'calendarArrowRight'" />
      </button>
      <span class="w-2"></span>
      <select :value="selectedDate" @change="onChange($event)">
        <option v-for="item in dateListData" :key="item.localDate">
          {{ item.localDate }}
        </option>
      </select>
      <span class="w-2"></span>
      <button class="mr-auto hover:bg-blue-700 bg-blue-500 text-white font-bold py-2 px-1 rounded"
        @click="setSelectedDateByDirection('today')">
        <IconSelector :iconText="'calendarToday'" />
      </button>
    </div>
    <template v-if="userData">
      <p v-for="item in dateListData" :key="item.date">
        <DateCard v-if="item.localDate == selectedDate" :cardData="item" :userData="userData" />
      </p>
    </template>
  </div>
</template>

<script>
import DateCard from "./DateCard.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import IconSelector from "./combinedIcons/IconSelector.vue";

export default {
  data() {
    return {
    };
  },
  name: "HelloWorld",
  props: {
    msg: String,
  },
  components: {
    DateCard,
    IconSelector
  },
  computed: {
    ...mapGetters("auth", {
      gettersAuthData: "getAuthData",
    }),
    ...mapGetters("users", {
      dateListData: "dateListData",
      selectedDate: "selectedDate",
      userData: "userData",
    }),
  },
  created() {
    this.fetchDateIntervalData();
    this.fetchUserData();
    this.setSelectedDate(this.prepareSelectedDate());
  },
  methods: {
    ...mapActions("users", {
      getDateListData: "getDateListData",
      getUserData: "getUserData",
    }),
    ...mapMutations("users", {
      setSelectedDate: "setSelectedDate",
    }),
    setSelectedDateByDirection(direction) {
      const actualSelectedIndex = this.dateListData.findIndex((e) => e.localDate == this.selectedDate)
      switch (direction) {
        case 'dateBefore':
          this.setSelectedDate(this.dateListData[Math.max(0, actualSelectedIndex - 1)].localDate)
          break;
        case 'dateNext':
          this.setSelectedDate(this.dateListData[Math.min(this.dateListData.length - 1, actualSelectedIndex + 1)].localDate)
          break;
        case 'today':
          this.setSelectedDate(this.prepareSelectedDate())
          break;
      }
    },
    onChange(event) {
      this.setSelectedDate(event.target.value)
    },
    prepareSelectedDate() {
      let nowDate = new Date(Date.now());
      nowDate.setHours(0, 0, 0, 0);

      return nowDate.toLocaleDateString("de-DE");
    },
    async fetchUserData() {
      await this.getUserData({ userId: this.gettersAuthData.userId });
    },
    async fetchDateIntervalData() {
      await this.getDateListData();
      // console.log("Da", this.selectedDate)
      if (this.selectedDate == null) {
        // console.log("Hier")
        this.setSelectedDate(this.prepareSelectedDate())
      }
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
