<template>
  <div class="flex">
    <div class="w-1/2">&#189;</div>
    <div class="w-1/2">
      <PartyPopper />
    </div>
  </div>
</template>

<script>
import PartyPopper from "vue-material-design-icons/PartyPopper.vue";

export default {
  name: "HalfHolidayIcon",
  components: {
    PartyPopper,
  },
};
</script>
